// 3rd
import { z } from 'zod';

// App - Types
import { StoryStatusesDto, castStoryStatusDtoToStoryStatus } from '@/types/story/dtos/story-status';
import {
  RequirementStatusesDto,
  castRequirementStatusDtoToRequirementStatus,
} from '@/types/story/requirements-analysis/dtos';
import type { RequirementStoryOccurrence } from '../../../types/requirement-story-occurrence';
import {
  ZodRequirementAntiPatternDto,
  castRequirementAntiPatternDtoToRequirementAntiPattern,
} from './requirement-anti-pattern';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodRequirementStoryOccurrenceDto = z.object({
  antiPatterns: z.array(ZodRequirementAntiPatternDto),
  applicationId: z.string().optional(),
  applicationName: z.string().optional(),
  lastModified: z.string(),
  requirementStatus: z.enum(RequirementStatusesDto),
  storyId: z.string(),
  storyName: z.string(),
  storyStatus: z.enum(StoryStatusesDto),
});

export type RequirementStoryOccurrenceDto = z.infer<typeof ZodRequirementStoryOccurrenceDto>;

export const castRequirementStoryOccurrenceDtoToRequirementStoryOccurrence = (
  dto: RequirementStoryOccurrenceDto
): RequirementStoryOccurrence => {
  return {
    id: dto.storyId,
    applicationId: dto.applicationId,
    applicationName: dto.applicationName,
    name: dto.storyName,
    priority: 'UNKNOWN',
    status: castStoryStatusDtoToStoryStatus(dto.storyStatus),
    lastUpdated: normalizeDatesToISOString(dto.lastModified),
    antiPatterns: dto.antiPatterns.map(castRequirementAntiPatternDtoToRequirementAntiPattern),
    requirementStatus: castRequirementStatusDtoToRequirementStatus(dto.requirementStatus),
  };
};
